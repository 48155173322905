<template>
  <b-card no-body class="mb-0">
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-25"> {{ label }} </b-card-title>
        <b-card-text v-if="loading" class="mb-0"> Loading .. </b-card-text>
        <b-card-text v-else class="mb-0">
          {{ new Date().getFullYear() }} Total: <b>{{ total }}</b>
        </b-card-text>
      </div>
    </b-card-header>

    <b-card-body class="pb-0">
      <b-skeleton v-if="loading" width="100%" height="240px"></b-skeleton>
      <!-- apex chart -->
      <vue-apex-charts
        v-else
        type="line"
        height="240"
        :options="salesLine.chartOptions"
        :series="salesLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BSkeleton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  props: ["label", "values", "total", "loading"],
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BSkeleton,
  },
  watch: {
    loading(v) {
      if (!this.values || v) return;
      this.salesLine.series[0].data = this.values;
    },
  },
  data: () => ({
    salesLine: {
      series: [
        {
          name: "Orders",
          data: [0],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: "line",
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        grid: {
          borderColor: "#ebe9f1",
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        colors: ["#df87f2"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            inverseColors: false,
            gradientToColors: [$themeColors.primary],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
          },
          axisTicks: {
            show: false,
          },
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          axisBorder: {
            show: false,
          },
          tickPlacement: "on",
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: "#b9b9c3",
              fontSize: "0.857rem",
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
            },
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    },
  }),
};
</script>
