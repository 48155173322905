import api from '@axios';

class Analytics {
	// Get Admin dashboard information
	static async dashboardAdmin() {
		const { data } = await api.get(`/analytics/dashboard-admin`);
		return data;
	}
	// Get User credit
	static async credit() {
		const { data } = await api.get(`/analytics/credit`);
		return data;
	}
	// Get all Orders
	static async ordersCount() {
		const { data } = await api.get(`/analytics/orders`);
		return data;
	}
	// Get all Orders by months (fetchAll -> admin)
	static async ordersMonth() {
		const { data } = await api.get('/analytics/orders-month');
		return data;
	}
	// Get more info about Orders Stats
	static async ordersInfo(time = 'alltime') {
		const { data } = await api.get(`/analytics/orders-statistics/${time}`);
		return data;
	}
	// Get more about api info
	static async apiInfo() {
		const { data } = await api.get(`/analytics/api-info`);
		return data;
	}
}

export default Analytics;
